import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_SEARCH_CRITERIA, TariffCodeSearchCriteria } from "domain/entity/TariffCode/TariffCodeSearchCriteria";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffCodeModel } from "presentation/model/TariffCode/TariffCodeModel";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffCodeVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffCodeModel>> | ((value: SetStateAction<TariffCodeModel>) => void),
    ],
    tariffTypeRepo: TariffTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
}

export const TariffCodeVM = ({dispatch,tariffTypeRepo,standardTariffCodeRepo}:TariffCodeVMProps) => {
    const [tariffCodeDispatch] = dispatch;
    
    const onShowLoading = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows:any[]) => {
        tariffCodeDispatch(prevState => {
            
            const moveAfterList = prevState.tariffCodeEntityList.filter(item => !rows.some(ety => ety.id === item.id));
            const subMenuItemArray: MenuItem[] = [];

            moveAfterList.forEach(item =>{
                const labelName = item.seq + ". " + item.tariffType + "/" + item.tariffCode;
                
                const isExisted = subMenuItemArray.some(subItem => subItem.label === labelName);
                if(!isExisted){
                    subMenuItemArray.push({
                        label: labelName,
                        command: () => {
                            handleMoveAfter(rows, item);
                        }
                    });
                }
            });
            
            return {
                ...prevState,
                selectedRows: rows,
                subMenuItemArray:subMenuItemArray
            }
        })
    }

    const handleMoveAfter = async (rows: StandardTariffCodeEntity[], targetItem: StandardTariffCodeEntity) => {
        let targetTarCodeItems: any[] = [];        

        tariffCodeDispatch(prevState => {  
            const allDatas = prevState.tariffCodeEntityList;

            const tempTarCodeItems = allDatas.filter(itemData => !rows.some(row => itemData.id === row.id)); 

            let targetIndex:number = -1;
            for (let i = 0; i < tempTarCodeItems.length; i++) {
                const tempRow = tempTarCodeItems[i];

                if(targetItem.seq === tempRow.seq){
                    targetIndex = i;
                }
            }               
            
            targetTarCodeItems = [...tempTarCodeItems.slice(0, targetIndex + 1), ...rows, ...tempTarCodeItems.slice(targetIndex + 1, tempTarCodeItems.length)];                  
            return {
                ...prevState,
                tariffCodeEntityList: [],
            } 
        });


        console.table(targetTarCodeItems);
        await standardTariffCodeRepo.onApply({
            tariffCodeItemList: targetTarCodeItems
        }).then((data) => {                
                tariffCodeDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"applySuccess":"apply successful."}    
                    }
                })
        }).catch((error) => {
            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"applyFail":"apply failed."}    
                }
            })           
        })        
    }

    const onRowDoubleClick = (entity: StandardTariffCodeEntity) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: entity,   
            }
        })
    }

    const loadDropdownOption = async() => {

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tarTypes => {
                let tariffTypeDropdownOptions = tarTypes?.map((tarType) => ({
                    dropdownLabel: tarType.tariffType,
                    tagLabel: tarType.tariffType,
                    value: tarType.tariffType,
                })) ?? []

                tariffCodeDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })            

                tariffCodeDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onSearchCriteriaResetClick = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_TARIFF_CODE_SEARCH_CRITERIA
            }
        })
    }

    const onSearchClick = async() => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }



    const searchTariffCode = async(searchCriteria: TariffCodeSearchCriteria) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: [],
                tariffCodeEntityList: [],
                currentSelectedRow: {...EMPTY_STANDARD_TARIFF_CODE_ENTITY}
            }
        })
        await standardTariffCodeRepo.findAllActiveUnActiveStandardTariffCodes().then((data) => {
            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    tariffCodeEntityList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {  
        
        const val =  e.target.value;
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === '' ? val.toUpperCase().replace(/\s+/g, '') : val,
            }
        }))
    };


    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "tariffCodeList" ? [] : '') : e),
            }
        }))
    }


    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return tariffCodeDispatch(prevState => {
          return {
              ...prevState,
              [fieldKey]: val
          }
        });
      }

    const onAddClick = () => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            isShowDetail: true,
        }))
    };

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldKey]: val
                },
            }
        })
    }

    const onUpload = () => {

    }


    return {
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchClick: onSearchClick,
        searchTariffCode: searchTariffCode,
        onInputTextChange: onInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDropdownChange: onDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onTextAreaChange:onTextAreaChange,
        onAddClick:onAddClick,
        onUpload: onUpload,
    }
}