import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { TariffCodeVM } from "presentation/viewModel/TariffCode/TariffCodeVM";
import { useMemo } from "react";

export const useTariffCodeVM = () => {
    const [, setTariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useMemo(() =>
        TariffCodeVM({
            dispatch: [setTariffCodeState],
            tariffTypeRepo: TariffTypeRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
        }), [setTariffCodeState])

    return tariffCodeVM
}

