import { SelectionChangedEvent } from "ag-grid-community";
import { StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import _ from "lodash";
import { INITIAL_TARIFF_CODE_COL_DEF, transferRowData } from "presentation/constant/TariffCode/TariffCodeColumnDefinition";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, RightClickMenu } from "veronica-ui-component/dist/component/core";


const TariffCodeTablePanel:React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const {tariffCodeEntityList, selectedRows,subMenuItemArray} = tariffCodeState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;
    const messageBarVM = useMessageBarVM();   
    
    useEffect(() => {
        const columnDefs = (INITIAL_TARIFF_CODE_COL_DEF.slice());

        if (!selectedRows || selectedRows.length <= 0) {
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);        
        const selectedRows = e.api.getSelectedRows();

        tariffCodeVM.updateSelectedRows(selectedRows);
      }, [tariffCodeVM])

    useEffect(() => {
            if (onTableSelectionClicked) return;
            
            gridRef?.current?.gridRef.current.api?.deselectAll();
        }, [onTableSelectionClicked])

      const handleRowDoubleClick = useCallback((entity: StandardTariffCodeEntity) => {
          tariffCodeVM.onRowDoubleClick(entity);
    }, [tariffCodeVM])

    useEffect(() => {
        const handleRightClickMenu = (event:any) => {            
            if(_.isEmpty(selectedRows) || !allowUpdate){                
                return; 
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
          document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])
   

    const moveItemList: MenuItem[] = useMemo(() => {
        const menuItemArray: MenuItem[] = [];

        menuItemArray.push({
            label: 'Move After',
            items:subMenuItemArray
        })
        return menuItemArray;
    },[subMenuItemArray]);

    const memoTariffCodeTable = useMemo(() => {

        return (<div id="myDiv">
            {(allowUpdate) && <RightClickMenu disabled={_.isEmpty(selectedRows)} element={rightClickRef} items={moveItemList} />}
        <HPHTable
            id='tariff-code-table'
            isNewColumnSetting={true}
            columns={INITIAL_TARIFF_CODE_COL_DEF.slice()}
            data={transferRowData(tariffCodeEntityList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={true}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: StandardTariffCodeEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        </div>
        );
    },[allowUpdate, handleRowDoubleClick, handleSelectionChange, moveItemList, rightClickRef, selectedRows, tariffCodeEntityList])

    return <><TableWrapper>
            {memoTariffCodeTable}
        </TableWrapper>
        </>;
}

export default memo(TariffCodeTablePanel);
