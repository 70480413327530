import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TariffCodeConstant } from "./TariffCodeConstant";

const TAR_CODE_MAIN_CONSTANT = TariffCodeConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_TARIFF_CODE_COL_DEF: any[] = [
        {
            headerName: TAR_CODE_MAIN_CONSTANT.SEQ,
            field: "seq",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            //pinned: "left",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_TYPE,
            field: "tariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_CODE,
            field: "tariffCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.PARENT_TARIFF_TYPE,
            field: "parentTariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.PARENT_TARIFF_CODE,
            field: "parentTariffCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_GROUP_ID_ONE,
            field: "tariffGroupId1",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_GROUP_ID_TWO,
            field: "tariffGroupId2",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_GROUP_ID_THREE,
            field: "tariffGroupId3",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_SEQ_ONE,
            field: "tariffSeq1",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_SEQ_TWO,
            field: "tariffSeq2",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_SEQ_THREE,
            field: "tariffSeq3",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_SCHEME,
            field: "tariffSchemeCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_NATURE,
            field: "tariffNature",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.PERCENTAGE,
            field: "percentage",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_CODE_DESC,
            field: "tariffCodeDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_CODE_DESCOTHER,
            field: "tariffCodeDescOther",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.INCLUDE_IN_THROUGHPUT,
            field: "includeTputInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: TAR_CODE_MAIN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: { [key: string]: string }, fieldName: string) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
