import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import TariffCodeDetailContMain from "presentation/view/container/TariffCode/TariffCodeDetailContMain";
import TariffCodeMaintenance from "./TariffCodeMaintenance";

export const TariffCodeMDView: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
     const isShowDetail = tariffCodeState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <TariffCodeMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><TariffCodeDetailContMain/> </div>
    } 

    </>
}