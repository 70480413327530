
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useRef, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCodeTitleBar:React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const messageBarVM = useMessageBarVM();    
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Title;
    const [ isLoading ] = useState<boolean>(false);

  
    const uploadRef = useRef<any>();
    const handleAdd = useCallback(() => {
        tariffCodeVM.onAddClick();
    }, [tariffCodeVM]);

    const handleUpload = useCallback(async () => {
        uploadRef.current?.click();
    }, []);

    const handleFileChange = (event:any) => {
        const files = event.target?.files;
        const file = files[0];

        if(!file){
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showWarining('Please upload a .csv file');
            return
        }
        
        tariffCodeVM.onUpload()

        messageBarVM.showWarining('TBC, we are building!');
    };


    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{TARIFF_CODE_CONSTANT.TARIFF_CODE_MAIN}</HeaderTitle>
        {(tariffCodeState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <input ref={uploadRef} type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} />
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} /> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} onClick={handleAdd}/>
            <div className="add-seperator"/>
            {<HPHButton disabled={false} label={TARIFF_CODE_CONSTANT.UPLOAD} size={'Small'} theme={'Secondary'} onClick={handleUpload} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffCodeTitleBar);